import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {FaGithub, FaLinkedinIn} from "react-icons/fa6";

function HomeAbout() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h2 style={{ fontSize: "30px" }}>
              SOMETHING ABOUT <span className="orange"> ME </span>
            </h2>
            <p className="home-about-body">
              I'm work in{" "}
              <i>
                <strong className="orange">
                  HTML, CSS, TypeScript and Angular
                </strong>
              </i>
              <br />
              <br />
              Currently expanding my knowledge in{" "}
              <strong>NodeJS & C#</strong>
              <br />
              <br />
              Also, I'm currently studying{" "}
              <i>
                <strong>Computer Science</strong>
              </i>{" "}
              at the University of Applied Sciences in Zagreb and working as a Developer.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h2>WHERE TO FIND ME</h2>
            <p>
              <span className="orange">Reach me on: </span>
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/mestrovicd"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-color  home-social-icons">
                  <FaGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/dario-me%C5%A1trovi%C4%87-7b2434189/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-color  home-social-icons">
                  <FaLinkedinIn />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default HomeAbout;
