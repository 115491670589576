import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaGithub, FaInstagram, FaLinkedinIn } from "react-icons/fa6";

function Footer() {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <Container fluid className="footer">
      <Row>
        <Col md="4" className="footer-copywright"></Col>
        <Col md="4" className="footer-copywright">
          <h3>Copyright © {year} Dario Meštrović</h3>
        </Col>
        <Col md="4" className="footer-body">
          <ul className="footer-icons">
            <li className="social-icons">
              <a
                href="https://github.com/mestrovicd"
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer">
                <FaGithub />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://www.linkedin.com/in/dario-me%C5%A1trovi%C4%87-7b2434189/"
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer">
                <FaLinkedinIn />
              </a>
            </li>
            <li className="social-icons">
              <a
                href="https://www.instagram.com/mestrovicd/"
                style={{ color: "white" }}
                target="_blank"
                rel="noopener noreferrer">
                <FaInstagram />
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
