import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import development from "../../assets/development.svg";
import Particle from "../Particle";
import HomeAbout from "./HomeAbout";
import Type from "./Type";

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="d-flex justify-content-center flex-column">
              <h1 style={{ paddingBottom: 15 }} className="heading">
                Dario Meštrović
              </h1>

              <div
                style={{ padding: 30, textAlign: "left", paddingBottom: 75 }}>
                <Type />
              </div>
            </Col>

            <Col md={5} style={{ paddingBottom: 20 }}>
              <img
                src={development}
                alt="development languages"
                className="img-fluid"
                style={{ maxHeight: "450px" }}
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <HomeAbout />
    </section>
  );
}

export default Home;
