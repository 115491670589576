import React from "react";
import Card from "react-bootstrap/Card";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            I'm <span className="orange">Dario Meštrović </span>
            from Zagreb, Croatia.
            <br /> I'm a 20-something student of{" "}
            <i>
              <strong>Computer Science </strong>
            </i>
            at University of Applied Sciences in Zagreb.
            <br />
            Working as a developer.
            <br />
            <br />
            My knowledge includes:
          </p>
          <ul>
            <li className="about-activity">- HTML, CSS (SCSS) & JS</li>
            <li className="about-activity">- React & Angular</li>
            <li className="about-activity">- Basic NodeJS & C#</li>
            <li className="about-activity">
              - Wordpress, Webflow, Contentful & AEM
            </li>
            <li className="about-activity">- and much more...</li>
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
